import React from 'react'

const Loader = () => {
  return (
    <>
    <div className='loader-container'>
    <h5 className='text-center' style={{marginTop:"200px"}}>Please Wait While Loading</h5>
    <div className='loader'></div>
    </div>
    
    </>
  )
}

export default Loader