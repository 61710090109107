export default [
  {
    tabname: "Dashboard",
    tabroute: "dashboard",
    fontlogo: "fa-solid fa-house",
  },
  
  {
    tabname: "Client Jobs",
    tabroute: "jobs",
    fontlogo: "fa-solid fa-user",
  },
  {
    tabname: "Assigned Jobs",
    tabroute: "assigned-job",
    fontlogo: "fa-solid fa-chart-line",
  },
  {
    tabname: "Active", 
    tabroute: "active-candidates",
    fontlogo: "fa-solid fa-fire",
  },
  {
    tabname: "Booked",
    tabroute: "booked-candidates",
    fontlogo: "fa-solid fa-address-book",
  },
  {
    tabname: "Pending",
    tabroute: "pending-candidates",
    fontlogo: "fa-solid fa-star",
  },
  {
    tabname: "Availability",
    tabroute: "availability-log",
    fontlogo: "fa-solid fa-list",
  },
  {
    tabname: "All Jobs",
    tabroute: "all-jobs-feeds",
    fontlogo: "fa-solid fa-square-poll-vertical",
  }
];
