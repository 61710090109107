module.exports = [
  "Admin",
  "Advanced Practitioner",
  "Anesthesia Tech",
  "Behavioral Health Tech",
  "Cardiovascular Technologist",
  "Cat Scan Tech",
  "Cath Lab Technologist",
  "Certified Scrub Tech",
  "Certified Surgical Tech",
  "CMA",
  "CNA",
  "Companion",
  "Consultant",
  "CSFA",
  "CT Technologist",
  "Cytotechnologist",
  "Dental",
  "Dialysis Tech",
  "Dietician",
  "Dietitian",
  "Dosimetrist",
  "Echo Technologist",
  "EEG Tech",
  "Electrophysiology Technologist",
  "EMT",
  "Endoscopy Tech",
  "Environmental",
  "Food Services",
  "Hemodialysis Tech",
  "Histologist",
  "Histology Tech",
  "House Supervisor",
  "Housekeeper",
  "Hyperbaric Tech",
  "JR Degree Value",
  "Lab Specialist",
  "Licensed Case Worker",
  "LPN",
  "LPN/LVN",
  "LVN",
  "Mammo Tech",
  "Medical Assistant",
  "Medical Coder",
  "Mental Health Tech",
  "Mental Health Technician",
  "Monitor Tech",
  "MRI Tech",
  "Nuclear Medical Technician",
  "Nurse Anesthetist",
  "Nurse Practitioner",
  "OB Tech",
  "OR",
  "Ortho Tech",
  "Orthotist",
  "OT",
  "Paramedic",
  "Pathology Assistant",
  "Patient Care",
  "Patient Care Tech",
  "Perfusionist",
  "Pharmacist",
  "Pharmacy Tech",
  "Phlebotomist",
  "Physician",
  "Physician Assistant",
  "Prosthetist",
  "Psychologist",
  "PT",
  "PTA",
  "RAD Tech",
  "Radiation Therapist",
  "Registered Vascular Specialist",
  "Respiratory Therapist",
  "RN",
  "RN/LPN",
  "RNFA",
  "RNFA/CSFA",
  "Scrub Tech",
  "Service Tech",
  "Sitter",
  "Sleep Tech",
  "SLP",
  "Social Worker",
  "Sonographer",
  "Sterile Processing Tech",
  "Surgical Supply Tech",
  "Surgical Tech",
  "Teacher",
  "Ultrasound Tech",
  "Vascular Tech",
];
