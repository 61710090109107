export default [
  {
    iconleft: "fa-solid fa-user",

    subheader: "User",

    icon: "fas fa-chevron-right",

    items: [
      {
        title: "Add User",

        path: "/dashboard/add-user",
      },

      {
        title: "View User",

        path: "/dashboard/view-user",
      },
    ],
  },

  {
    iconleft: "fa-solid fa-id-card-clip",

    subheader: "Employees",

    icon: "fas fa-chevron-right",

    items: [
      {
        title: "Employee Controls",

        path: "/dashboard/view-employee",
      },
    ],
  },

  {
    iconleft: "fa-solid fa-building-columns",

    subheader: "Projects",

    icon: "fas fa-chevron-right",

    items: [
      {
        title: "Manage Projects",

        path: "/dashboard/view-project",
      },
    ],
  },

  {
    iconleft: "fa-solid fa-users",

    subheader: "Client",

    icon: "fas fa-chevron-right",

    items: [
      {
        title: "Add Client",

        path: "/dashboard/add-client",
      },

      {
        title: "View Client",

        path: "/dashboard/view-client",
      },
    ],
  },

  {
    iconleft: "fa-solid fa-file-export",

    subheader: "Facility",

    icon: "fas fa-chevron-right",

    items: [
      {
        title: "Add Facility",

        path: "/dashboard/add-facility",
      },

      {
        title: "View Facility",
        path: "/dashboard/view-facility",
      },
    ],
  },

  {
    iconleft: "fa-solid fa-briefcase",

    subheader: "VMS",

    icon: "fas fa-chevron-right",

    items: [
      {
        title: "Add VMS",

        path: "/dashboard/add-vms",
      },

      {
        title: "View VMS",

        path: "/dashboard/view-vms",
      },
    ],
  },

  {
    iconleft: "fa-solid fa-briefcase",

    subheader: "Change-Logs",

    icon: "fas fa-chevron-right",

    items: [
      {
        title: "Employee Logs",

        path: "/dashboard/employee-logs",
      },

      {
        title: "Project Logs",

        path: "/dashboard/project-logs",
      },

      {
        title: "Project Extension Logs",

        path: "/dashboard/project-extension-logs",
      },

      {
        title: "Timesheet Logs",

        path: "/dashboard/timesheet-logs",
      },
    ],
  },

  {
    iconleft: "fa-solid fa-briefcase",

    subheader: "Templates",

    icon: "fas fa-chevron-right",

    items: [
      {
        title: "View Templates",

        path: "/dashboard/view-templates",
      },
    ],
  },

  {
    iconleft: "fa-solid fa-briefcase",

    subheader: "Checklist",

    icon: "fas fa-chevron-right",

    items: [
      {
        title: "View Checklist",

        path: "/dashboard/view-checklist",
      },
    ],
  },
  {
    iconleft: "fa-solid fa-circle-exclamation",
    subheader: "Pending Documents",
    icon: "fas fa-chevron-right",
    items: [
      {
        title: "View Documents",
        path: "/dashboard/view-pending",
      },
    ],
  },
];
