import React from "react";
export default [
  //   {
  //     iconleft: "fa-solid fa-user",
  //     subheader: "User",
  //     icon: "fas fa-chevron-right",
  //     items: [
  //       {
  //         title: "Add User",
  //         path: "/dashboard/add-user",
  //       },
  //       {
  //         title: "View User",
  //         path: "/dashboard/view-user",
  //       },
  //     ],
  //   },
  //   {
  //     iconleft: "fa-solid fa-id-card-clip",
  //     subheader: "Employees",
  //     icon: "fas fa-chevron-right",
  //     items: [
  //       {
  //         title: "Employee Controls",
  //         path: "/dashboard/view-employee",
  //       },
  //     ],
  //   },
  //   {
  //     iconleft: "fa-solid fa-building-columns",
  //     subheader: "Projects",
  //     icon: "fas fa-chevron-right",
  //     items: [
  //       {
  //         title: "Manage Projects",
  //         path: "/dashboard/view-project",
  //       },
  //     ],
  //   },
  //   {
  //     iconleft: "fa-solid fa-briefcase",
  //     subheader: "Checklist",
  //     icon: "fas fa-chevron-right",
  //     items: [
  //       {
  //         title: "View Checklist",
  //         path: "/dashboard/view-checklist",
  //       },
  //     ],
  //   },
];
