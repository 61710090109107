export default [
  {
    tabname: "Dashboard",
    tabroute: "dashboard",
    fontlogo: "fa-solid fa-house",
  },

 
  // {
  //   tabname: "Jobs",
  //   tabroute: "jobs",
  //   fontlogo: "fa-solid fa-square-poll-vertical",
  // },
  {
    tabname: "Assigned Job",
    tabroute: "assigned-job",
    fontlogo: "fa-solid fa-star",
  },
  // {
  //   tabname: "Active",
  //   tabroute: "active-candidates",
  //   fontlogo: "fa-solid fa-fire",
  // },
  {
    tabname: "Booked",
    tabroute: "booked-candidates",
    fontlogo: "fa-solid fa-address-book",
  },
  // {
  //   tabname: "Pending",
  //   tabroute: "pending-candidates",
  //   fontlogo: "fa-solid fa-star",
  // },
  // {
  //   tabname: "Availability",
  //   tabroute: "availability-log",
  //   fontlogo: "fa-solid fa-list",
  // },
  // {
  //   tabname: "VMS",
  //   tabroute: "job-vms",
  //   fontlogo: "fa-solid fa-list",
  // },
  {
    tabname: "Assign VMS",
    tabroute: "vms-config",
    fontlogo: "fa-solid fa-table",
  },
  {
    tabname: "All Jobs",
    tabroute: "all-jobs-feeds",
    fontlogo: "fa-solid fa-square-poll-vertical",
  },
  {
    tabname: "Feed Stats",
    tabroute: "feed-stats",
    fontlogo: "fa-solid fa-table",
  },
];
