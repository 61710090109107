export default [
  {
    iconleft: "fa-solid fa-id-card-clip",
    subheader: "Employees",
    icon: "fas fa-chevron-right",
    items: [
      {
        title: "Employee Controls",
        path: "/dashboard/view-employee",
      },
    ],
  },

  {
    iconleft: "fa-solid fa-building-columns",
    subheader: "Projects",
    icon: "fas fa-chevron-right",
    items: [
      {
        title: "Manage Projects",
        path: "/dashboard/view-project",
      },
    ],
  },
];
