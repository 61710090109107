import React from "react";

export default [
  //   {
  //     iconleft: <i class="fa-solid fa-user"></i>,
  //     subheader: "User",
  //     icon: <i class="fas fa-chevron-right"></i>,
  //     items: [
  //       {
  //         title: "Add User",
  //         path: "/dashboard/add-user",
  //       },
  //       {
  //         title: "View User",
  //         path: "/dashboard/view-user",
  //       },
  //     ],
  //   },
  //   {
  //     iconleft: <i class="fa-solid fa-id-card-clip"></i>,
  //     subheader: "Employees",
  //     icon: <i class="fas fa-chevron-right"></i>,
  //     items: [
  //       {
  //         title: "Employee Controls",
  //         path: "/dashboard/view-employee",
  //       },
  //     ],
  //   },
  //   {
  //     iconleft: <i class="fa-solid fa-building-columns"></i>,
  //     subheader: "Projects",
  //     icon: <i class="fas fa-chevron-right"></i>,
  //     items: [
  //       {
  //         title: "Manage Projects",
  //         path: "/dashboard/view-project",
  //       },
  //     ],
  //   },
  //   {
  //     iconleft: <i class="fa-solid fa-users"></i>,
  //     subheader: "Client",
  //     icon: <i class="fas fa-chevron-right"></i>,
  //     items: [
  //       {
  //         title: "Add Client",
  //         path: "/dashboard/add-client",
  //       },
  //       {
  //         title: "View Client",
  //         path: "/dashboard/view-client",
  //       },
  //     ],
  //   },
  //   {
  //     iconleft: <i class="fa-solid fa-file-export"></i>,
  //     subheader: "Facility",
  //     icon: <i class="fas fa-chevron-right"></i>,
  //     items: [
  //       {
  //         title: "Add Facility",
  //         path: "/dashboard/add-facility",
  //       },
  //       {
  //         title: "View Facility",
  //         path: "/dashboard/view-client",
  //       },
  //     ],
  //   },
  //   {
  //     iconleft: <i class="fa-solid fa-briefcase"></i>,
  //     subheader: "VMS",
  //     icon: <i class="fas fa-chevron-right"></i>,
  //     items: [
  //       {
  //         title: "Add VMS",
  //         path: "/dashboard/add-vms",
  //       },
  //       {
  //         title: "View VMS",
  //         path: "/dashboard/view-vms",
  //       },
  //     ],
  //   },
  //   {
  //     iconleft: <i class="fa-solid fa-briefcase"></i>,
  //     subheader: "Change-Logs",
  //     icon: <i class="fas fa-chevron-right"></i>,
  //     items: [
  //       {
  //         title: "Employee Logs",
  //         path: "/dashboard/employee-logs",
  //       },
  //       {
  //         title: "Project Logs",
  //         path: "/dashboard/project-logs",
  //       },
  //       {
  //         title: "Project Extension Logs",
  //         path: "/dashboard/project-extension-logs",
  //       },
  //       {
  //         title: "Timesheet Logs",
  //         path: "/dashboard/timesheet-logs",
  //       },
  //     ],
  //   },
];
