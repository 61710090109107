module.exports = [
  "Accountant",
  "Accounting Analyst",
  "Admin Assisstant",
  "Admin Assistant",
  "Assistant Director",
  "Billing Rep",
  "Chief Quality Officer",
  "Clerical Assistant",
  "Clerk",
  "Clinical Liason",
  "Clinical Pharmacy Manager",
  "Controller",
  "Coordinator",
  "Credential Coordinator",
  "Data Entry Specialist",
  "Director of Business Development",
  "Director of Cancer Center",
  "Director of OR",
  "Executive Assistant",
  "Financial Representative",
  "HR Assistant",
  "HR Recruiter",
  "IT Applications Manager",
  "Lab Manager",
  "Make Ready Tech",
  "Manager-Sterile Processing",
  "Medical Director",
  "Office Assistant",
  "Project Assitant",
  "Revenue Integrity Manager",
  "Scheduling Rep",
  "Secretary",
  "Senior Accountant",
  "APRN",
  "Anesthesia Tech",
  "Certified Anesthesia Tech",
  "Behavioral Health Tech",
  "CV Tech",
  "Cat Scan Tech",
  "Cardiac Cath Lab",
  "Cath Lab Tech",
  "Cath Lab Tech Manager",
  "EP Tech/Cath Lab Tech",
  "OB/GYN",
  "Certified Surgical Tech",
  "Certified Surgical Tech CV",
  "Certified Surgical Tech CVOR",
  "Certified Surgical Tech CVOR Peds",
  "Certified Surgical Tech Neuro CVOR",
  "Certified Surgical Tech Neuro OR",
  "Certified Surgical Tech OB",
  "Certified Surgical Tech OR",
  "Certified Surgical Tech OR Spine&Total Joint",
  "CMA",
  "CMA Assisted Living",
  "CMA Skilled Nursing",
  "CNA",
  "CNA Assisted Living",
  "CNA LTC",
  "CNA Med Surg",
  "CNA PCT",
  "CNA Skilled Nursing",
  "LTC",
  "Companion",
  "Business Consultant Manager",
  "Chief Nurse Consultant",
  "First Assist",
  "First Assist OR",
  "First Assist Tech",
  "CT Tech",
  "CT Tech PET/CT",
  "CT Tech X-ray",
  "X-Ray Tech",
  "Cytotechnologist",
  "Dental Assistant",
  "Dental Hygienist",
  "Certified Hemo Dialysis Tech",
  "Dialysis Tech",
  "Certified Hemo DialysisTech",
  "Dietician",
  "Pediatric Clinical Dietician",
  "Registered Dietician",
  "Dosimetrist",
  "CV Echo Sonographer/Ultrasound TECH",
  "Echo Tech",
  "Echo Tech Cardiac Imaging",
  "Echo Tech Cardiographer/Vascular",
  "Echo Tech Peds",
  "Echo Tech Vascular Tech",
  "Echo Tech/Radiology",
  "EEG Neuro",
  "EEG Tech",
  "EEG Tech Respiratory Neuro",
  "EKG Neurodiagnostic",
  "Certified EP Tech",
  "EP Tech",
  "EP Tech Cardiac",
  "EP Tech Radiology Cardiology",
  "RCIS w/ EP",
  "EMT",
  "Endo Tech",
  "GI Tech",
  "Housekeeping Supervisor",
  "Cook",
  "Food Service",
  "Food Service Associate",
  "Food Service Coordinator",
  "Clinical Hemodialysis Tech",
  "Histologist",
  "Certified Histo Tech",
  "Histology Tech",
  "Lead Histology Tech",
  "House Supervisor",
  "Housekeeper",
  "Hyperbaric Therapy Tech",
  "JR Job Specialty",
  "Clinical Lab Scientist",
  "Lab Assistant",
  "Lab Specialist",
  "Lab Tech",
  "Laboratory Coordinator",
  "Med Tech",
  "Med Tech ASCP",
  "MLT",
  "MLT/MLS",
  "MT ASCP-Microbiology",
  "MT/MLS",
  "MT/MLT",
  "EP Lab",
  "MT",
  "Licensed Case Worker",
  "Assisted Living",
  "Charge",
  "Drug Alcohol",
  "Home Health",
  "LPN",
  "LPN/MA",
  "LTC",
  "Med Surg",
  "Outpatient",
  "Psych",
  "Skilled Nursing",
  "Clinic",
  "ICU",
  "LPN/LVN",
  "LTAC",
  "Outpatient",
  "Psych",
  "Skilled Nursing",
  "Clinic/Ambulatory",
  "ICU",
  "LVN",
  "LVN Med Surg",
  "Mammo Tech",
  "Radiology Cardiology",
  "Medical Assistant",
  "Office Practice",
  "Registered Medical Assistant",
  "Coding Specialist",
  "Mental Health Technician",
  "Mental Health Technician",
  "Monitor Tech",
  "MRI RAD Tech",
  "MRI Tech",
  "Radiology Cardiology",
  "Nuclear Med Technician",
  "Nuclear Med Technician / Radiology",
  "CRNA",
  "Critical Care",
  "ER",
  "Familty",
  "ICU",
  "Medical&Dental",
  "Midwife",
  "Neonatal",
  "Nurse Practitioner",
  "Nurse Practitioner/OP Cardiology",
  "Nurse Practitioner/Physician Assistant",
  "Psych",
  "L&D Tech",
  "OB Tech",
  "Scrub Tech",
  "Circulate",
  "Circulation/Scrub",
  "Scrub Tech",
  "Ortho Tech",
  "Certified Orthotist",
  "Orthotist",
  "COTA",
  "OT",
  "OT Rehab",
  "Therapy Rehab",
  "CCU/ICU",
  "Pathology Assistant",
  "Patient Assess Supervisor",
  "Patient Care Associate",
  "Patient Care Tech",
  "Patient Coordinator",
  "Patient Escort",
  "Patient Serivce Rep",
  "Personal Care Worker",
  "Resident Care Aide",
  "Med Surg",
  "PCT ER",
  "ER",
  "PCT PCU",
  "Med Surg Tele",
  "Perfusionist",
  "Pharmacist",
  "Pharmacy Manager",
  "Certified Pharmacy Tech",
  "Pharmacy Tech",
  "Lab Assistant",
  "Phlebotomist",
  "Phlebotomist / Laboratory",
  "Critical Care/Pulmonary",
  "Family Medicine",
  "OB",
  "OB/GYN",
  "Otolaryngologist (ENT MD/DO)",
  "Physician",
  "Critical Care",
  "Physician Assistant",
  "Surgery Physician Assistan",
  "Certified Orthotist Prosthetist",
  "School Psychologist",
  "Cert Lymphedema Therapist Outpatient",
  "Director of Rehab",
  "Home Health",
  "Outpatient",
  "Physical Therapist",
  "Physical Therapist Assistant",
  "Physical Therapist Assistant Rehab",
  "Cardiac RAD Tech",
  "Cardiovascular RAD Tech",
  "Cath Lab",
  "Cath Lab Special Procedure Tech",
  "CV Tech",
  "Electro Physiology",
  "Endovascular Lab Clinical Coordinator",
  "Interventional Rad Tech",
  "Interventional Rad Tech Angio Tech",
  "Interventional Rad Tech ARU",
  "Interventional Rad Tech Radiology",
  "Interventional Rad Tech/Special Procedure Tech",
  "Interventional Rad Tech/Special Procedures Tech",
  "OR Director",
  "RAD Tech",
  "Sonographer",
  "Special Procedures Tech",
  "Special Procedures Technologist",
  "X-ray",
  "Radiation Therapist",
  "Cardiac Registered Vascular Tech",
  "Certified Respiratory Therapist",
  "Registered Respiratory Therapist",
  "Registered Respiratory Therapist NICU",
  "Registered Respiratory Therapist/Certified Respiratory Therapist",
  "Respiratory Care Practitioner",
  "Respiratory Therapist",
  "Respiratory Therapist NICU",
  "Acute Care",
  "Acute Care ED",
  "Acute Care ICU",
  "Acute Care Med Surg",
  "Acute Care Oncology",
  "Acute Med Surg",
  "Acute Pulmonary",
  "Admin",
  "Admin Assisstant",
  "Admin Assistant",
  "Adult Care",
  "Ambulatory Care",
  "Ambulatory Care/Urology/Pain Mgmt",
  "ARU",
  "Assistant Mgr",
  "Associates",
  "Bone Marrow",
  "Bone Marrow Oncology",
  "Burn ICU",
  "Burn ICU Med Surg Tele",
  "Burn OR Circulator",
  "Burn Unit",
  "Burn Unit Med Surg",
  "Cardiac",
  "Cardiac CAT",
  "Cardiac Cath Lab",
  "Cardiac Cath Lab Special Procedures",
  "Cardiac ICU",
  "Cardiac IMU",
  "Cardiac Intermediate Care",
  "Cardiac Intermediate Care Tele",
  "Cardiac Med Surg Tele",
  "Cardiac Neuro",
  "Cardiac Neuro Tele",
  "Cardiac Tele",
  "Cardiovascular",
  "Cardiovascular Intensive Peds",
  "Case Management",
  "Case Management Cath Lab",
  "Cath Lab",
  "Cath Lab EP",
  "Cath Lab PACU",
  "CCU",
  "CCU Inpatient Fast Response",
  "CCU/ICU",
  "CCU/ICU Neuro CCU",
  "CCU/IMC",
  "Central Stroke",
  "Certified Nurse Midwife",
  "Certified Wound Care",
  "Charge",
  "Charge Cardiac Intermediate Tele",
  "Charge Cardiac Tele",
  "Charge ER",
  "Charge ICU",
  "Charge ICU/CCU",
  "Charge Main OR",
  "Charge OR",
  "Charge PCU",
  "Charge Skilled Nursing",
  "Charge Surgery",
  "Charge Tele",
  "Chart Review",
  "CICU",
  "CICU Peds",
  "Circulation OR",
  "Circulator OR",
  "Clinic",
  "Clinic / Ambulatory",
  "Clinical Decisions",
  "Clinical Transition Unit",
  "Congenital Heart Surgery Peds",
  "Coordinator",
  "Corrections",
  "CPCU/PCU",
  "Critical Care",
  "Critical Care Peds",
  "CRU",
  "CSICU",
  "CV Heartcare",
  "CVICU",
  "CVOR",
  "CVOR Open Heart Surgery Peds",
  "CVOR Peds",
  "CVPCU",
  "Diabetic CU Tele",
  "Diabetic Endocrine Unit",
  "Dialysis",
  "Dialysis IP/OP",
  "Dialysis Renal",
  "Director Home Health",
  "Director of Care Coordination",
  "Director of Case Management",
  "Director of Case Management RN",
  "Director of Cath Lab",
  "Director of Critical Care",
  "Director of Emergency Services",
  "Director of Infection Prevention",
  "Director of Main OR",
  "Director of Med Surg",
  "Director of Nursing Care Center",
  "Director of Nursing Services",
  "Director of Nursing-Surgical Services",
  "Director of OR",
  "Director of Patient Safety",
  "Director of Patient Safety RN",
  "Director of Pediatric Cardiac Service",
  "Director of Quality Mgt.",
  "Director of Surgical Services",
  "Director of Women Services",
  "Discharge Planner",
  "Drug Alcohol",
  "ED",
  "ED ER",
  "ED Hold",
  "ED Peds",
  "ED SS",
  "Endoscopy",
  "Endoscopy GI",
  "Endoscopy PACU Oupatient",
  "EP",
  "EP Cath Lab",
  "EP Lab",
  "EP Med Surg",
  "Epidemiologist",
  "ER",
  "ER Acute Care",
  "ER Med Surg",
  "ER Peds",
  "First Assist",
  "Float Pool",
  "FSED",
  "General",
  "Geriatrics",
  "GI Lab",
  "Heart Lab/ARU",
  "Hematology Oncology",
  "Hematology Oncology Peds",
  "Home Care",
  "Home Health",
  "Home Health Psych",
  "Home Health/Hospice",
  "Hospice",
  "House Supervisor",
  "ICU",
  "ICU Cardiac",
  "ICU ER Med Surg",
  "ICU Hold",
  "ICU Hold Tele",
  "ICU IMU",
  "ICU Med Surg",
  "ICU Neuro",
  "ICU Neuro OR",
  "ICU Observation Unit",
  "ICU PCU",
  "ICU Peds",
  "ICU Surgical Trauma",
  "ICU/CCU",
  "ICU/ED",
  "IM ISCU",
  "IMC",
  "IMC Neuro",
  "IMCCU",
  "IMCU Tele",
  "IMU",
  "Infection Prevention",
  "Infection Prevention Specialist",
  "Infusion",
  "Infusion Oncology",
  "Inpatient",
  "Intermediate Care",
  "Intermediate Tele",
  "Internal Medicine",
  "Interventional Cardiology Recovery",
  "Interventional Radiology",
  "Interventional Therapy",
  "IR",
  "IR Special Procedure",
  "L&D",
  "L&D Manager",
  "L&D OB/GYN",
  "L&D Recovery Postpartum",
  "L&D/Med Surg",
  "LTAC",
  "LTAC Med Surg",
  "LTC",
  "Manager",
  "Manager of Cardiac Cath Lab",
  "Manager of Cath Lab",
  "Manager of CCU",
  "Manager of ICU",
  "Manager of ICU Clinical Adult",
  "Manager of Inpatient Rehab",
  "Manager of Inpatient Rehabilitation",
  "Manager of L&D and Ante Partum",
  "Manager of Med Surg Department",
  "Manager of Neurophysilogy Clinical",
  "Manager of Pediatrics Congenital Heart Surgery Unit CHSU",
  "Manager of PICU",
  "Manager of Surgical Services",
  "Manager/Director",
  "MDS Coordinator LTC",
  "Med Surg",
  "Med Surg Cardiac",
  "Med Surg CDOU",
  "Med Surg Comprehensive",
  "Med Surg Dialisys",
  "Med Surg ICU",
  "Med Surg Mother Baby/Postpartum",
  "Med Surg Nephrology",
  "Med Surg Neuro",
  "Med Surg Neuro/Trauma",
  "Med Surg Observation",
  "Med Surg Oncology",
  "Med Surg Orthopedic",
  "Med Surg Orthopedic Spine",
  "Med Surg Orthopedic/Neuro",
  "Med Surg Orthopedics",
  "Med Surg Orthopedics Tele",
  "Med Surg Orthopedics/Neuro",
  "Med Surg PCU",
  "Med Surg PCU Tele",
  "Med Surg Peds",
  "Med Surg Post Op",
  "Med Surg Rehab",
  "Med Surg Tele",
  "Med Surg Trauma Tele",
  "Med Surge Oncology",
  "MICU",
  "Mother Baby",
  "Mother Baby Postpartum",
  "MSP",
  "MSP High Needs",
  "Neonatal",
  "Neonatal ICU",
  "Neuro",
  "Neuro Combination Unit",
  "Neuro Interventional Radiology",
  "Neuro OR",
  "Neuro Orthopedics",
  "Neuro/Acute Epilepsy Unit",
  "Neurology",
  "Newborn Nursary",
  "Newborn Nursery",
  "NICU",
  "Non Core",
  "NSICU",
  "NTICU",
  "Nursing Education",
  "OB",
  "OB/GYN",
  "Observation CDU Tele",
  "Observation DOU",
  "Observation Unit",
  "Oncology",
  "Open Heart",
  "Open Heart Surgery CVOR Adult",
  "OR",
  "OR Circulator",
  "OR Clinical Educator",
  "OR First Assist or Tech",
  "OR ICU/SICU",
  "OR Orthopedics",
  "OR PACU",
  "OR Peds",
  "OR SCVS",
  "OR Spine",
  "OR Spine&Total Joint",
  "OR Trauma",
  "Orthopedics",
  "Orthopedics Surgical",
  "Orthopedics/Podiatry",
  "Outpatient",
  "Outpatient Clinic",
  "PACU",
  "PACU GI/Endoscopy",
  "PACU Recovery Room",
  "Patient Care Coordinator",
  "PCCU",
  "PCNT/PCU",
  "PCU",
  "PCU ED Hold",
  "PCU Neuro",
  "PCU Observation",
  "PCU Tele",
  "Peds",
  "Perinatal",
  "PICC",
  "PICU",
  "Post Cath Lab",
  "Post Surgical",
  "Postpartum",
  "Postpartum/Mom Baby",
  "Pre Op",
  "Pre Op Outpatient",
  "Pre Op PACU",
  "Pre-Admission Testing",
  "PRN",
  "Procedural Support",
  "Psych",
  "Psych Adult",
  "Psych ER",
  "Psych Senior",
  "Pulmonary",
  "Quality Coordinator",
  "Radiology",
  "Radiology SPC",
  "Rehab",
  "Rehab Acute",
  "Renal",
  "Renal PCU",
  "Resource",
  "Respiratory Therapist",
  "RN IMCCU",
  "School Nurse",
  "SCU",
  "Senior Services",
  "Short Stay",
  "SICU",
  "SICU Trauma",
  "Skilled Nursing",
  "SNF",
  "Specialty Nurse",
  "Staff Nurse",
  "Stepdown",
  "Supervisor",
  "Supervisor of Oncology",
  "Supervisor of Open Heart CVOR Peds",
  "Surgical Services",
  "TCU",
  "Technologist",
  "Tele",
  "Tele Nephrology",
  "Tele Neuro",
  "Telemetry",
  "Transplant",
  "Trauma",
  "Trauma ICU",
  "Trauma Tele",
  "Unit Manager",
  "Urology",
  "Utilization Review",
  "Wound Care",
  "CCU Unit Manager",
  "PCICU",
  "ICU Neuro Trauma",
  "Echo Lab",
  "Urgent Care",
  "Obstetrics",
  "MSICU",
  "ED",
  "General",
  "LTC",
  "LTC/Skilled Nursing",
  "OB",
  "Skilled Nursing",
  "Staff Nurse",
  "Unknown",
  "Certified First Assist",
  "CVOR First Assist",
  "First Assist",
  "Cardiac RNFA or STFA First Assist",
  "CVOR or CVOR Tech",
  "Scrub Tech",
  "Scrub Tech MDS Coordinator",
  "Service Tech",
  "Sitter",
  "Certified Sleep Tech",
  "Polysomnographer",
  "Polysomnographer Cardiopulmanology",
  "Sleep Tech",
  "SLP",
  "Assistant",
  "Certified Social Worker",
  "Licensed Social Worker",
  "Social Worker",
  "Social Worker - Case Management",
  "Licensed Master Social Worker",
  "Cardiac Sonographer",
  "Cardiac Sonographer / Radiology",
  "Cardiac Ultrasound",
  "Cardiovascular Sonographer",
  "Echo Sonographer",
  "Registered Cardiac Sonographer",
  "Sonographer",
  "Vascular Sonographer",
  "Cariac Sonographer",
  "Manager-Sterile Processing",
  "Sterile Processing Tech",
  "Sterile Processing Tech CSS",
  "Supervisor",
  "Surgical Supply Tech",
  "Endoscopy",
  "ER Tech",
  "GI Tech",
  "Instrument Processing Tech",
  "L&D",
  "OB Scrub Tech L&D",
  "Surgical Tech",
  "Surgical Tech Cardiac",
  "Surgical Tech CVOR",
  "Surgical Tech Open Heart OR",
  "Surgical Tech Open Heart Peds",
  "ER Tech",
  "Special Education",
  "MFM",
  "OB Sonographer",
  "Sonographer",
  "Ultra Sono Radiol",
  "Ultrasound Tech",
  "Vascular Ultrasound Tech",
  "Angiography",
];
