// export const jobshost = "http://192.168.1.99:9291/api/"; //Dheeraj Sir
// export const host = "http://192.168.1.172:8080/api/v1/"; //Mayank Sir
// export const host = "http://192.168.1.172:8090/"; //Mayank Sir
export const host = "https://api.midastech.org/";
export const jobshost = "http://3.239.97.174:9291/api/"; //Mayank Sir
// export const vercelHost = "http://192.168.1.250:9000/";
export const vercelHost = "https://api-midas-legal-com.vercel.app/";
export const nodeHost = "https://checklist.midascrm.tech/";
// export const dhost = "http://192.168.0.229:8000/";
export const dhost = "https://zoho.midascrm.tech/";
export const token =
  "1000.d4c20c616f6b2fd10a473c6436177db9.28ee1cee3798e18c8a27bfae911ce18f";
export const managerId = 7;
export const teamlead = 6;
export const recruiter = 5;
