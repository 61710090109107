export default [
  {
    value: "AM_ASSIGNED_TL",
    label: "Acc. Manager Assigned Team Lead",
  },
  {
    value: "TL_ASSIGNED_FINAL_ASSIGNEE",
    label: "Team Lead Assigned Recruiter",
  },
  {
    value: "AM_ASSIGNED_RECRUITER",
    label: "Acc. Manager Assigned Recruiter",
  },
];
